module.exports = {
  messages: {
    COMPANY_DIRECTORY_LIST_H: 'Companies starting with the letter',
    COMPANY_DIRECTORY_OTHERS: 'More',
    DISCIPLINE_1001: 'Analysis and statistics',
    DISCIPLINE_1002: 'Administration',
    DISCIPLINE_1003: 'Consulting',
    DISCIPLINE_1004: 'Customer service',
    DISCIPLINE_1005: 'Purchasing, materials management & logistics',
    DISCIPLINE_1006: 'Finance, accounting & controlling',
    DISCIPLINE_1007: 'Teaching, R&D',
    DISCIPLINE_1008: 'Health, medical and social',
    DISCIPLINE_1009: 'Graphic design and architecture',
    DISCIPLINE_1010: 'Engineering and technical',
    DISCIPLINE_1011: 'IT and software development',
    DISCIPLINE_1012: 'Management and corporate development',
    DISCIPLINE_1013: 'Marketing and advertising',
    DISCIPLINE_1014: 'HR',
    DISCIPLINE_1015: 'PR and journalism',
    DISCIPLINE_1016: 'Production and manufacturing',
    DISCIPLINE_1017: 'Product management',
    DISCIPLINE_1018: 'Project management',
    DISCIPLINE_1019: 'Process planning & QA',
    DISCIPLINE_1020: 'Legal',
    DISCIPLINE_1021: 'Sales and commerce',
    DISCIPLINE_1022: 'Other',
    EMPLOYMENT_TYPE_CONTRACTOR: 'Self-employed',
    EMPLOYMENT_TYPE_CONTRACTOR_LOCAL_SERPS: 'Freelancer',
    EMPLOYMENT_TYPE_FULL_TIME: 'Full-time',
    EMPLOYMENT_TYPE_INTERN: 'Student',
    EMPLOYMENT_TYPE_PART_TIME: 'Part-time',
    EMPLOYMENT_TYPE_SEASONAL: 'Seasonal',
    EMPLOYMENT_TYPE_STUDENT_LOCAL_SERPS: 'Student jobs',
    EMPLOYMENT_TYPE_TEMPORARY: 'Temp',
    EMPLOYMENT_TYPE_TEMPORARY_LOCAL_SERPS: 'Mini',
    EMPLOYMENT_TYPE_VOLUNTARY: 'Volunteer',
    JOB_SEO_CATEGORY_JOBS: '{category} Jobs',
    JOB_SEO_CITY_JOBS: 'Jobs in {city}',
    JOB_SEO_COMPANY_JOBS: '{term} jobs',
    JOB_SEO_EMPLOYMENT_TYPE_: 'Ehrenamt-Jobs',
    JOB_SEO_EMPLOYMENT_TYPE_CONTRACTOR: 'Freelancer-Jobs',
    JOB_SEO_EMPLOYMENT_TYPE_FULL_TIME: 'Vollzeit-Jobs',
    JOB_SEO_EMPLOYMENT_TYPE_INTERN: 'Studierende-Jobs',
    JOB_SEO_EMPLOYMENT_TYPE_PART_TIME: 'Teilzeit-Jobs',
    JOB_SEO_EMPLOYMENT_TYPE_SEASONAL: 'Saisonarbeit-Jobs',
    JOB_SEO_EMPLOYMENT_TYPE_TEMPORARY: 'Aushilfe-Jobs',
    JOB_SEO_JOB_TYPE_CONTRACTOR_JOBS: 'Find freelancer projects now.',
    JOB_SEO_JOB_TYPE_FULL_TIME_JOBS: 'Find your new full-time job now.',
    JOB_SEO_JOB_TYPE_INTERN_JOBS: 'Find the right internship now.',
    JOB_SEO_JOB_TYPE_PART_TIME_JOBS: 'Find your new part-time job now.',
    JOB_SEO_JOB_TYPE_SEASONAL_JOBS: 'Find your next seasonal job now.',
    JOB_SEO_JOB_TYPE_TEMPORARY_JOBS: 'Find your next temporary job now.',
    JOB_SEO_JOB_TYPE_VOLUNTARY_JOBS: 'Find your new voluntary work now.',
    JOB_SEO_SERPS_EMPTY_SEARCH: 'No results for {keyword}',
    JOB_SEO_TERM_JOBS: '{term} jobs',
    JOBS_SEARCH_EMPTY_STATE_NO_RESULTS_FOR_LABEL: 'for',
    JOBS_SEARCH_EMPTY_STATE_NO_RESULTS_TITLE: 'No results found',
    MP_000_RESOURCE_JOB_TYPES_TEMPORARY: 'Temporary',
    XJM_BREADCRUMB_HOME: 'Jobs',
    XJM_JOBS_SEO_ROLE_FAQ_HEADLINE: 'Frequently asked questions',
    XJM_JOBS_SEO_ROLE_FAQ_TOP_CITY_ANSWER:
      'The most popular city for {jobRole} is {firstCity}.',
    XJM_JOBS_SEO_ROLE_FAQ_TOP_CITY_QUESTION:
      'Where are the most openings for the role of {jobRole}?',
    XJM_JOBS_SEO_ROLE_FAQ_TOP_SKILL_ANSWER:
      '{firstSkill} is especially good to have for the role of {jobRole}.',
    XJM_JOBS_SEO_ROLE_FAQ_TOP_SKILL_QUESTION:
      'What skills are needed for {jobRole}?',
    XJM_JOBS_SEO_ROLE_FAQ_TOP_THREE_CITIES_ANSWER:
      'The most popular cities for {jobRole} are {firstCity}, {secondCity}, and {thirdCity}.',
    XJM_JOBS_SEO_ROLE_FAQ_TOP_THREE_SKILLS_ANSWER:
      '{firstSkill}, {secondSkill} and {thirdSkill} are especially good to have for the role of {jobRole}.',
    XJM_JOBS_SEO_ROLE_FAQ_TOP_TWO_CITIES_ANSWER:
      'The most popular cities for {jobRole} are {firstCity} and {secondCity}.',
    XJM_JOBS_SEO_ROLE_FAQ_TOP_TWO_SKILLS_ANSWER:
      '{firstSkill} and {secondSkill} are especially good to have for the role of {jobRole}.',
    XJM_JOBS_SEO_ROLE_RELATED_JOBS_HEADLINE: 'Related jobs',
    XJM_JOBS_SEO_ROLE_SALARY_DESCRIPTION:
      'The majority of people in the role of {jobRole} in Germany earn between {minimumSalary} and {maximumSalary}, with the average being €{averageSalary}.',
    XJM_JOBS_SEO_ROLE_SALARY_HEADER:
      'How much do you earn in the role of {jobRole}?',
    XJM_JOBS_SEO_ROLE_SALARY_LABEL: 'Average annual salary',
    XJM_META_DESCRIPTION_CATEGORY:
      "{category} vacancies: Find jobs at top {category} employers and interesting articles about tomorrow's working world.",
    XJM_META_DESCRIPTION_CATEGORY_CITY:
      "Find great {category} vacancies in {city} - Employers with reviews, interesting articles about tomorrow's working world and valuable contacts at your dream company...",
    XJM_META_DESCRIPTION_CATEGORY_TERM:
      "Find great {term} jobs in {category} - Employers with reviews, interesting articles about tomorrow's working world and valuable contacts at your dream company",
    XJM_META_DESCRIPTION_CITY_WITH_POSTINGS:
      '{postings} jobs in {city} on the XING jobs network. Find just the right job for you.',
    XJM_META_DESCRIPTION_COMPANY:
      '{term} jobs on the XING jobs network ✓ Browse salary ranges ✓ Find your dream job now on XING!',
    XJM_META_DESCRIPTION_EMPLOYMENT_TYPE_CITY_WITH_POSTINGS:
      '{postings} {employmentType} jobs in {city} on the XING jobs network. Find just the right job for you.',
    XJM_META_DESCRIPTION_JOB_TYPE:
      "Find {job_type} vacancies: Current job offers, top companies and interesting articles about tomorrow's working world.",
    XJM_META_DESCRIPTION_JOB_TYPE_CATEGORY:
      "Find {category} {job_type} vacancies - Employers with reviews, interesting articles about tomorrow's working world and valuable contacts at your dream company...",
    XJM_META_DESCRIPTION_JOB_TYPE_TERM:
      "Find {term} {job_type} vacancies - Employers with reviews,interesting articles about tomorrow's working world and valuable contacts at your dream company...",
    XJM_META_DESCRIPTION_JOB_TYPE_TERM_MINIJOBS:
      '✔ {term}-Stellenangebote (Mini-Jobs) ✔ Die beliebtesten Arbeitgeber ✔ Wissenswertes zur Arbeitswelt von heute',
    XJM_META_DESCRIPTION_SKILL:
      ' {postings} {term} jobs on the XING jobs network. Find just the right job for you.',
    XJM_META_DESCRIPTION_SKILL_TYPE_CITY:
      '{postings} {term} jobs in {city} on the XING jobs network.',
    XJM_META_DESCRIPTION_TERM_ANY_WITH_POSTINGS:
      '{postings} {term} jobs on the XING jobs network. Find just the right job for you.',
    XJM_META_DESCRIPTION_TERM_CITY_WITH_POSTINGS:
      '{postings} {role} jobs in {city} on the XING jobs network. Find just the right job for you.',
    XJM_META_DESCRIPTION_TERM_WITH_POSTINGS:
      '{postings} {role} jobs on the XING jobs network. Find just the right job for you.',
    XJM_META_TITLE_CATEGORY: 'Find {category} vacancies in XING Jobs!',
    XJM_META_TITLE_CATEGORY_CITY:
      'Find {category} vacancies {city} in XING Jobs!',
    XJM_META_TITLE_CATEGORY_TERM: 'Find {term} jobs in {category}',
    XJM_META_TITLE_CITY_WITH_DATE:
      'Current jobs & vacancies in {city} - {month} {year}',
    XJM_META_TITLE_COMPANY: '{term} jobs | XING jobs network',
    XJM_META_TITLE_EMPLOYMENT_TYPE_CITY_WITH_DATE:
      '{employmentType} jobs in {city} - {month} {year}',
    XJM_META_TITLE_JOB_TYPE: 'Find great {job_type} vacancies in XING Jobs!',
    XJM_META_TITLE_JOB_TYPE_CATEGORY:
      'Find {category} {job_type} vacancies in XING Jobs!',
    XJM_META_TITLE_JOB_TYPE_TERM:
      'Find {term} {job_type} vacancies in XING Jobs!',
    XJM_META_TITLE_JOB_TYPE_TERM_MINIJOBS:
      '{term} Mini-Jobs | XING: Jobs, die passen.',
    XJM_META_TITLE_SKILL: 'Current {term} jobs - {date}',
    XJM_META_TITLE_SKILL_TYPE_CITY:
      'Current {term} jobs in {city} - {month} {year}',
    XJM_META_TITLE_TERM_ANY_WITH_DATE: 'Current {term} jobs - {month} {year}',
    XJM_META_TITLE_TERM_CITY_WITH_POSTINGS:
      '{role} jobs in {city} - {month} {year}',
    XJM_META_TITLE_TERM_WITH_DATE: '{role} jobs & vacancies - {month} {year}',
    XJM_SEARCH_TITLE_CATEGORY_CITY: '{category} vacancies in {city}',
    XJM_SEARCH_TITLE_CATEGORY_TERM: 'Find {term} jobs in {category}',
    XJM_SEARCH_TITLE_EMPLOYMENT_TYPE_CITY: '{employmentType} jobs in {city}',
    XJM_SEARCH_TITLE_EMPLOYMENT_TYPE_CITY_FREELANCER:
      'Freelancer Jobs in {city}',
    XJM_SEARCH_TITLE_EMPLOYMENT_TYPE_CITY_STUDENTEN: 'Studentenjobs in {city}',
    XJM_SEARCH_TITLE_JOB_TYPE_CATEGORY: 'Find {category} {job_type} vacancies',
    XJM_SEARCH_TITLE_JOB_TYPE_CATEGORY_MINIJOBS: '{category} Minijobs',
    XJM_SEARCH_TITLE_JOB_TYPE_TERM: '{job_type} {term} vacancies',
    XJM_SEARCH_TITLE_SKILL_TYPE_CITY: '{term} jobs in {city}',
    XJM_SEARCH_TITLE_TERM_CITY_WITH_DATE: '{role} jobs in {city}',
    XJM_SEO_DIRECTORY_PAGINATION_H: 'Search jobs by first letter',
    XJM_SEO_JOB_ROLE: '{term} jobs',
    XJM_SEO_RADIUS: 'and within a radius of {radius} km',
    XJM_SEO_RELATED_JOB_TITLES_BY_SKILL: 'Other {skill} jobs',
    XJM_SEO_RELATED_SKILLS_BY_JOB_ROLE: 'Popular job searches',
    XJM_SEO_TOP_CITIES_NEARBY_TITLE: 'Nearby cities',
    XJM_SEO_TOP_CITIES_TITLE: 'Top cities',
    XJM_SEO_TOP_JOB_ROLE_LOCATIONS: 'Cities offering {role} jobs',
    XJM_SEO_TOP_JOBS_TITLE: 'Top jobs',
    XJM_SEO_TOP_JOBS_TYPES_TITLE: 'Job types',
    XJM_SEO_TOP_LOCATIONS_BY_SKILL: 'Cities offering {skill} jobs',
    XJM_SEO_XING_SERVICES_TITLE: 'XING Services',
    XJM_TEASER_LINKS_JOB_TYPE_TITLE: 'Jobs by employment type',
    XJM_TEASER_LINKS_JOBS: ' jobs',
    XJM_TEASER_LINKS_JOBS_IN: ' <strong>jobs</strong> in',
    XJM_TEASER_LINKS_TERM_TITLE: 'Most sought-after job titles',
  },
};
